<script>
export default {
  methods: {
    toCurrencyString(value, fractionDigits = 2) {
      if (isNaN(value)) value = 0;
      return value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });
    },
    toDecimalString(value) {
      if (isNaN(value)) value = 0;
      return value.toLocaleString('de-DE');
    },
    toPercentString(value) {
      if (isNaN(value)) value = 0;
      return value.toLocaleString('de-DE', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },

    toCurrencyOrDecimal(value, fractionDigits = 2) {
      return this.isCurrency ? this.toCurrencyString(value, fractionDigits) : this.toDecimalString(value);
    },
  },
};
</script>
