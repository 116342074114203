// TODO: Fix this mess.
// Reverting back to an enum would be great,
// but we need to assign [1] and [12] (GastroBlitz Shop) twice.
interface Interfaces {
  name: string;
  color: string;
  id: number;
}
const EnumInterfaceTypeDerived = [] as unknown as Interfaces[];
EnumInterfaceTypeDerived[0] = { name: 'Computer', color: '#636e72', id: 0 };
EnumInterfaceTypeDerived[1] = { name: 'GastroBlitz Shop', color: '#d63031', id: 1 };
EnumInterfaceTypeDerived[2] = { name: 'Lieferservice', color: '#00b894', id: 2 };
EnumInterfaceTypeDerived[3] = { name: 'BlitzCloud', color: '#55efc4', id: 3 };
EnumInterfaceTypeDerived[4] = { name: 'Lieferando', color: '#f57c00', id: 4 };
EnumInterfaceTypeDerived[5] = { name: 'Pizza.de', color: '#ffeaa7', id: 5 };
EnumInterfaceTypeDerived[6] = { name: 'Kasse', color: '#a29bfe', id: 6 };
EnumInterfaceTypeDerived[7] = { name: 'AppSmart', color: '#0984e3', id: 7 };
EnumInterfaceTypeDerived[8] = { name: 'Lieferheld', color: '#fd79a8', id: 8 };
EnumInterfaceTypeDerived[9] = { name: 'Foodora', color: '#e84393', id: 9 };
EnumInterfaceTypeDerived[10] = { ...EnumInterfaceTypeDerived[1], id: 10 };
EnumInterfaceTypeDerived[11] = { name: 'PizzeriaDe', color: '#81ecec', id: 11 };
EnumInterfaceTypeDerived[12] = { ...EnumInterfaceTypeDerived[1], id: 12 };
EnumInterfaceTypeDerived[14] = { name: 'SimplyDelivery', color: '#fab1a0', id: 14 };
EnumInterfaceTypeDerived[15] = { name: 'OrderCenterPush', color: '#2d3436', id: 15 };
EnumInterfaceTypeDerived[100] = { name: 'Unbekannt', color: '#000000', id: 100 };

export default EnumInterfaceTypeDerived;
