<template>
  <div class="orders">
    <v-alert dense type="info" v-if="selectedStores.length !== 0">Es wurden nur einige der letzten Bestellungen <strong>aller Shops</strong> geladen. Das Filtern nach Stores kann dazu führen, dass nur wenige oder keine Ergebnisse angezeigt werden.</v-alert>
    <v-container class="white" fluid rounded>
      <v-data-table :headers="tableHeaders" :items="tableData" :loading="loading" :footer-props="{ 'items-per-page-options': [orders.length / 3] }" dense>
        <template #item.TotalGross="{ item }">
          {{ toCurrencyString(parseFloat(item.TotalGross)) }}
        </template>

        <template v-for="slot in ['Date', 'DeliveryDate']" #[`item.${slot}`]="{ item }">
          {{ parseDate(item.Date) }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import EnumInterfaceTypeDerived from '@/enums/EnumInterfaceTypeDerived';
import EnumOrderType from '@/enums/EnumOrderType';
import ValueParseMixin from '@/mixins/ValueParseMixin.vue';

export default {
  mixins: [ValueParseMixin],
  mounted() {
    this.getOrders();
  },
  data: () => ({
    tableHeaders: [
      { text: 'Store', value: 'StoreName' },
      { text: 'Bestellnummer', value: 'OrderNumber' },
      { text: 'Bestellzeitpunkt', value: 'Date' },
      { text: 'Lieferzeitpunkt', value: 'DeliveryDate' },
      { text: 'Liefertyp', value: 'OrderTypeString' },
      { text: 'Schnittstelle', value: 'InterfaceTypeDerivedString' },
      { text: 'Name', value: 'Name' },
      { text: 'Straße', value: 'Street' },
      { text: 'PLZ', value: 'Postcode' },
      { text: 'Stadt', value: 'City' },
      { text: 'Gesamtbetrag', value: 'TotalGross', align: 'end' },
    ],
    orders: [],
    orderCount: 0,
    loading: undefined,
  }),
  methods: {
    parseDate(isoDateString) {
      return isoDateString === null ? '' : moment(isoDateString).utcOffset(0).format('HH:mm, DD.MM.YYYY');
    },
    async getOrders() {
      this.loading = true;
      const response = await this.$http.get('orders');
      this.orders = response.data;
      this.loading = false;
    },
  },
  computed: {
    stores() {
      return this.$store.state.stores;
    },
    selectedStores() {
      return this.$store.state.selectedStores;
    },
    tableData() {
      if (this.stores.length === 0) return [];
      let orders = [...this.orders];

      if (this.selectedStores.length !== 0) {
        orders = orders.filter((order) => this.selectedStores.includes(order.StoreOid));
      }

      orders.map((order) => {
        order.StoreName = this.stores.find(({ Oid }) => Oid === order.StoreOid).Name;
        order.InterfaceTypeDerivedString = EnumInterfaceTypeDerived[order.InterfaceTypeDerived].name;
        order.OrderTypeString = EnumOrderType[order.OrderType];
        return order;
      });

      return orders;
    },
  },
};
</script>
